import HARMONY_CONFIG from '@config';
import EnvironmentUtils from '@utils/environment.utils';
import NextHead from 'next/head';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';

interface SEOProps {
    title?: string;
    description?: string;
    image?: string;
    url?: string;
    ogImage?: string;
    children?: ReactNode;
}

const SEO: React.FC<SEOProps> = ({ title, description, image, ogImage, url, children }) => {
    const { pathname } = useRouter();
    const seoTitle = title ? `${title} | ${HARMONY_CONFIG.title}` : HARMONY_CONFIG.title;
    const seoImage = image || `https://${EnvironmentUtils.PUBLIC_URL}/icon-512x512.png`;

    return (
        <NextHead>
            <title>{seoTitle}</title>
            <meta name="name" content={seoTitle} />
            <meta name="description" content={description ? description : HARMONY_CONFIG.description} />
            <meta property="description" content={description ? description : HARMONY_CONFIG.description} />
            <meta name="image" content={seoImage} />
            <meta property="og:title" content={seoTitle} />
            <meta property="og:type" content={'website'} />
            <meta property="og:url" content={url || pathname} />
            <meta property="og:image" content={ogImage || `https://${EnvironmentUtils.PUBLIC_URL}/image/og-image.png`} />
            <meta property="og:description" content={description || HARMONY_CONFIG.ogDescription || HARMONY_CONFIG.description} />
            <meta property="og:site_name" content={HARMONY_CONFIG.title} />
            <link rel="icon" href={`https://${EnvironmentUtils.PUBLIC_URL}/favicon.ico`} />
            <script type="application/ld+json">
                {`{
                    "@context": "https://schema.org",
                    "@type": "OnlineStore",
                    "name": "${HARMONY_CONFIG.title}",
                    "url": "${EnvironmentUtils.PUBLIC_URL}",
                    "logo": "${seoImage}"
                }`}
            </script>
            {children}
        </NextHead>
    );
};

export default SEO;
