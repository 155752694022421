import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { DrawerContentProps } from 'src/legacy/components/common/drawer-content';
import DrawerHeaderA from './designA/drawer-header';
import DrawerHeaderB from './designB/drawer-header';
import DrawerHeaderC from './designC/drawer-header';
import { ComponentType } from 'react';

export interface DrawerHeaderProps extends Pick<DrawerContentProps, 'title' | 'subtitle' | 'headerButton' | 'alignTitleLeft' | 'logoOnDirect'> {
    hasDivider?: boolean;
    backgroundColor?: string;
}

const DrawerHeader: React.FC<DrawerHeaderProps> = (props) => {
    const Component = useDesignComponents<ComponentType<DrawerHeaderProps>>({
        [Design.DesignA]: DrawerHeaderA,
        [Design.DesignB]: DrawerHeaderB,
        [Design.DesignC]: DrawerHeaderC,
    });
    return <Component {...props} />;
};

export default DrawerHeader;
