import Logo from '@designs/primitives/main/header/logo';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { useReferrer } from '@hooks/use-referrer';
import RoutesEnum from '@routes';
import { IThemeProps } from '@type/common';
import ThemeUtils from '@utils/theme.utils';
import Link from 'next/link';
import CustomIconButton from 'src/legacy/components/common/custom-icon-button';
import { HeaderButtonType } from 'src/legacy/components/common/drawer-content';
import BodyText from 'src/legacy/components/common/text/body-text';
import { DrawerHeaderProps } from '..';

const DrawerHeaderC: React.FC<DrawerHeaderProps> = ({ logoOnDirect, headerButton, title, hasDivider = true, backgroundColor }) => {
    const { theme } = useHarmony();
    const { canGoBack } = useReferrer();

    return (
        <StickyContainer>
            <HeaderBar type={headerButton?.type} backgroundColor={backgroundColor} hasDivider={hasDivider} theme={theme}>
                {!canGoBack && logoOnDirect && <Logo />}
                {headerButton?.type === 'back' && (canGoBack || !logoOnDirect) && (
                    <CustomIconButton size="24px" onClick={headerButton.onClose}>
                        chevron_left
                    </CustomIconButton>
                )}
                <StyledTitle centered>
                    <BodyText weight="bold" size={'18px'} lineHeight="24px" color={theme.black}>
                        {title}
                    </BodyText>
                </StyledTitle>
                {headerButton?.type === 'close' && (
                    <AbsolutePositioned>
                        <CustomIconButton size="24px" className="right-icon" onClick={headerButton.onClose}>
                            close
                        </CustomIconButton>
                    </AbsolutePositioned>
                )}
                {headerButton?.type === 'home' && (
                    <Link href={RoutesEnum.Home}>
                        <CustomIconButton size="24px" className="right-icon" color={theme.gray[800]}>
                            home
                        </CustomIconButton>
                    </Link>
                )}
            </HeaderBar>
        </StickyContainer>
    );
};

export default DrawerHeaderC;

const StickyContainer = styled.div`
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: ${ThemeUtils.zIndex.header};
`;

const HeaderBar = styled.div<{ type?: HeaderButtonType } & IThemeProps & Pick<DrawerHeaderProps, 'backgroundColor' | 'hasDivider'>>`
    ${({ type, backgroundColor, hasDivider, theme }) => `
        display: flex;
        justify-content: ${type === 'close' ? 'center' : 'flex-start'};
        align-items: center;
        width: 100%;
        height: 54px;
        padding: 0 16px;
        background-color: ${backgroundColor};
        border-bottom: ${hasDivider && `1px solid ${theme.gray['50']}`}
    `}
`;

const AbsolutePositioned = styled.div`
    position: absolute;
    right: 18px;
`;

const StyledTitle = styled.div<{ centered: boolean }>`
    ${({ centered }) =>
        centered
            ? `
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
    `
            : ''}
`;
