import Logo from '@designs/primitives/main/header/logo';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { useReferrer } from '@hooks/use-referrer';
import RoutesEnum from '@routes';
import ThemeUtils from '@utils/theme.utils';
import Link from 'next/link';
import CustomIconButton from 'src/legacy/components/common/custom-icon-button';
import { DrawerHeaderStyleProps } from 'src/legacy/components/common/drawer-content';
import BodyText from 'src/legacy/components/common/text/body-text';
import { DrawerHeaderProps } from '..';

const DrawerHeaderA: React.FC<DrawerHeaderProps> = ({ logoOnDirect, headerButton, alignTitleLeft, title, subtitle }) => {
    const { theme } = useHarmony();
    const { canGoBack } = useReferrer();

    return (
        <HeaderBar>
            {!canGoBack && logoOnDirect && <Logo />}
            {headerButton?.type === 'back' && (canGoBack || !logoOnDirect) && (
                <CustomIconButton size="32px" onClick={headerButton.onClose} padding="0" className="back-button">
                    chevron_left
                </CustomIconButton>
            )}
            <AbsoluteTitle>
                <TitleContainer alignTitleLeft={alignTitleLeft}>
                    <NoWrapText alignTitleLeft={alignTitleLeft} weight="bold" size={alignTitleLeft ? '15px' : '18px'}>
                        {title}
                    </NoWrapText>
                    {subtitle !== undefined && (
                        <NoWrapText2 alignTitleLeft={alignTitleLeft} size={alignTitleLeft ? '15px' : '12px'} color={theme.gray[600]}>
                            {subtitle}
                        </NoWrapText2>
                    )}
                </TitleContainer>
            </AbsoluteTitle>
            {headerButton?.type === 'close' && (
                <CustomIconButton size="25px" className="right-icon" onClick={headerButton.onClose} padding="0">
                    close
                </CustomIconButton>
            )}
            {headerButton?.type === 'home' && (
                <Link href={RoutesEnum.Home}>
                    <CustomIconButton className="right-icon" size="25px" padding="0" id="home">
                        home
                    </CustomIconButton>
                </Link>
            )}
        </HeaderBar>
    );
};

export default DrawerHeaderA;

const HeaderBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding: 0 18px;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: ${ThemeUtils.zIndex.header};
`;

const AbsoluteTitle = styled.div<DrawerHeaderStyleProps>`
    ${({ alignTitleLeft }) => `
        position: absolute;
        top: 50%;
        left: ${alignTitleLeft ? '18px' : '50%'};
        width: ${alignTitleLeft ? '84%' : '70%'};
        height: 44px;
        transform: translate(${alignTitleLeft ? '0' : '-50%'}, -50%);
    `}
`;

const TitleContainer = styled.div<DrawerHeaderStyleProps>`
    ${({ alignTitleLeft }) => `
        display: flex;
        align-items: ${alignTitleLeft ? 'flex-start' : 'center'};
        justify-content: center;
        flex-direction: column;
        gap: 6px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
    `}
`;
const NoWrapText = styled(BodyText)<DrawerHeaderStyleProps>`
    ${({ alignTitleLeft }) =>
        alignTitleLeft
            ? `
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `
            : 'text-align: center'}
`;

const NoWrapText2 = styled(NoWrapText)`
    right: 0;
    top: unset;
    bottom: 0;
    left: 0;
`;
