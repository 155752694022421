import Logo from '@designs/primitives/main/header/logo';
import styled from '@emotion/styled';
import { useHarmony } from '@hooks/use-harmony';
import { useReferrer } from '@hooks/use-referrer';
import RoutesEnum from '@routes';
import { IThemeProps } from '@type/common';
import ThemeUtils from '@utils/theme.utils';
import Link from 'next/link';
import CustomIconButton from 'src/legacy/components/common/custom-icon-button';
import { HeaderButtonType } from 'src/legacy/components/common/drawer-content';
import BodyText from 'src/legacy/components/common/text/body-text';
import { DrawerHeaderProps } from '..';

const DrawerHeaderB: React.FC<DrawerHeaderProps> = ({ logoOnDirect, headerButton, title, hasDivider = true, backgroundColor = 'white' }) => {
    const { theme } = useHarmony();
    const { canGoBack } = useReferrer();

    return (
        <>
            <HeaderBar type={headerButton?.type} backgroundColor={backgroundColor}>
                {!canGoBack && logoOnDirect && <Logo />}
                {headerButton?.type === 'back' && (canGoBack || !logoOnDirect) && (
                    <CustomIconButton padding="0 6px 0 0" size="32px" onClick={headerButton.onClose} className="back-button">
                        chevron_left
                    </CustomIconButton>
                )}
                {title && (
                    <TitleContainer>
                        <BodyText as="h1" color="black" size="18px" weight="medium">
                            {title}
                        </BodyText>
                    </TitleContainer>
                )}
                {headerButton?.type === 'close' && (
                    <AbsolutePositioned>
                        <CustomIconButton size="25px" className="right-icon" onClick={headerButton.onClose}>
                            close
                        </CustomIconButton>
                    </AbsolutePositioned>
                )}
                {headerButton?.type === 'home' && (
                    <Link href={RoutesEnum.Home}>
                        <CustomIconButton className="right-icon" fill color={theme.gray[300]} size="25px" id="home">
                            home
                        </CustomIconButton>
                    </Link>
                )}
            </HeaderBar>
            {hasDivider && <DividerContainer theme={theme} />}
        </>
    );
};

export default DrawerHeaderB;

const HeaderBar = styled.div<{ type?: HeaderButtonType; backgroundColor: string }>`
    ${({ type, backgroundColor }) => `
        display: flex;
        justify-content: ${type === 'close' ? 'center' : 'flex-start'};
        align-items: center;
        width: 100%;
        height: 52px;
        flex-shrink: 0;
        padding: 0 16px;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        background:${backgroundColor};
        z-index: ${ThemeUtils.zIndex.header};
    `}
`;

const TitleContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: ${ThemeUtils.zIndex.below};
`;

const AbsolutePositioned = styled.div`
    position: absolute;
    right: 18px;
`;

const DividerContainer = styled.div<IThemeProps>`
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.gray['50']};
`;
